
import React from 'react';
import './App.css';
import server_pull from './assets/imgs/server_pull.png';
import module_list from './assets/imgs/module_list.png';
import cool from './assets/imgs/cool.png';
import logo from './assets/imgs/elevate_wider.png';
import logo_min from './assets/imgs/wave-svg.png';
import wave_foot from './assets/imgs/wave.svg';




function App() {
  return (
    <div id="main">
     <div class="default_logo">
		<img src={logo_min} alt={"logo_min"} />
	 </div>
	 
		<div class="card_group">
			<div class="default_card">
				<div class="defualt_card_header">
				<img src={server_pull} alt={"server_pull"} />
					<h2>Productivity</h2>
				</div>
				<div class="default_card_paragraph">
					<p>
						Our servers are easy to manage, you won't waste time.
					</p>
				</div>
			</div>
			<div class="default_card">
				<div class="defualt_card_header">
				<img src={module_list} alt={"module_list"} />
					<h2>Better production</h2>
				</div>
				<div class="default_card_paragraph">
					<p>
						Improve your business with your own solutions
					</p>
				</div>
			</div>
		
			<div class="default_card">
				<div class="defualt_card_header">
				<img src={cool} alt={"cool"} />
					<h2>High quality optic fiber</h2>
				</div>
				<div class="default_card_paragraph">
					<p>
						We can guarantee you won't be offline
					</p>
				</div>
			</div>
			
			

		
		</div>
		
		<div class="wave_foot_default">
			<img src={wave_foot} alt={"wave_foot"} />
		</div>
		
	 </div>
	  
	  
	  
  );
}

export default App;
